<template>
  <div>
    <ApplyMain ref="applymain" stepcurrent=3>
      <div slot="content" class="container">
        <div class="m">
          <el-form :model="form" :label-position="labelPosition" :inline-message="true" :rules="rules" size="mini"
            label-width="100px" class="elform" ref="form">
            <div class="mt">
              <h4 class="title">
                <span>税务登记证</span>
              </h4>
            </div>
            <div class="mc">
              <div>
                <el-form-item label="纳税人类型" label-width="185px" prop="taxpayerCertType">
                  <el-select placeholder="请选择纳税人类型" style="width:400px" v-model="form.taxpayerCertType">
                    <el-option v-for="item in taxpayerCertTypedata" :key="item.id" :label="item.data_title"
                      :value="item.data_title">
                    </el-option>
                  </el-select>
                </el-form-item>
              </div>
              <div>
                <el-form-item class="formL" label="纳税人识别号" label-width="185px" prop="taxpayerCertCode">
                  <el-input v-model="form.taxpayerCertCode" style="width:400px"></el-input>
                </el-form-item>
                <div class="form-describe-text desc-box">
                  <span>三证合一的请填写统一社会信用代码,一般纳税人税码为13%，小规模税码为3%</span>
                </div>
              </div>
              <div>
                <el-form-item label="纳税类型税码" label-width="185px" prop="taxTypeCode">
                  <el-select placeholder="请选择纳税类型税码" style="width:400px" v-model="form.taxTypeCode">
                    <el-option v-for="item in taxTypeCodedata" :key="item.id" :label="item.data_title"
                      :value="item.data_title">
                    </el-option>
                  </el-select>
                </el-form-item>
              </div>
              <div>
                <el-form-item label="税务登记证电子版" label-width="185px" prop="taxpayerCertImgUrl">
                  <div class="form-describe-text upload-pop-desc">
                    <span>请同时上传国税、地税的税务登记证，两者缺一不可，复印件加盖公司红章，[国地税合一示例] [国地税非合一示例]
                      如贵司所在地区已推行'三证合一'，此处请上传营业执照副本电子版。</span>
                  </div>
                  <el-upload class="" :limit="1" v-model="form.taxpayerCertImgUrl" :headers="myHeaders"
                    list-type="picture-card" :on-remove="handleRemove" :action="uploadUrl" name="file"
                    accept="image/png,image/gif,image/jpg,image/jpeg" :file-list="taxpayerCertImgfileList"
                    :on-success="busSuccess" :on-error="busError" :on-change="busChange" :show-file-list="false">
                    <img v-if="form.taxpayerCertImgUrl!=''" :src="form.taxpayerCertImgUrl" class="avatar">
                    <i v-else class="el-icon-plus"></i>
                  </el-upload>
                  <div class="el-upload__tip">图片尺寸请确保800px*800px以上，文件大小在1MB以内，支持png,jpeg,gif格式，最多可上传1张</div>
                </el-form-item>
              </div>
              <div>
                <el-form-item label="一般纳税人资格证电子版" label-width="185px" prop="taxRegCertImgUrl">
                  <div class="form-describe-text upload-pop-desc">
                    <span>三证合一地区请上传税务局网站上一般纳税人截图，复印件需加盖公司红章。</span>
                  </div>
                  <el-upload class="upload-demo" v-model="form.taxRegCertImgUrl" :headers="myHeaders"
                    list-type="picture-card" :on-remove="handleRemovetaxRegCertImg" :action="uploadUrl" name="file"
                    accept="image/png,image/gif,image/jpg,image/jpeg" :file-list="taxRegCertImgfileList"
                    :on-success="busSuccesstaxRegCertImg" :on-error="busErrortaxRegCertImg"
                    :on-change="busChangetaxRegCertImg" :show-file-list="false">
                    <img v-if="form.taxRegCertImgUrl!=''" :src="form.taxRegCertImgUrl" class="avatar">
                    <i v-else class="el-icon-plus"></i>
                  </el-upload>
                  <div class="el-upload__tip">图片尺寸请确保800px*800px以上，文件大小在1MB以内，支持png,jpeg,gif格式，最多可上传1张</div>
                </el-form-item>
              </div>
            </div>
            <div class="mt">
              <h4 class="title">
                <span>银行账号信息
                </span>
              </h4>
            </div>
            <div class="mc">
              <div>
                <el-form-item class="formL small" label-width="185px" label="银行开户名" prop="creditcode">
                  <span style="width: 400px;">{{form.bankAccountName}}</span>
                </el-form-item>
              </div>
              <div>
                <el-form-item class="formL small" label-width="185px" label="对公结算银行账号" prop="bankAccount">
                  <el-input v-model="form.bankAccount" style="width:400px"></el-input>
                </el-form-item>
                <div class="form-describe-text desc-box">
                  <span>用于平台与开店公司间账务结算</span>
                </div>
              </div>
              <div>
                <el-form-item class="formL small" label-width="185px" label="开户银行支行" prop="bankName">
                  <el-input v-model="form.bankName" style="width:400px"></el-input>
                </el-form-item>
                <div class="form-describe-text desc-box">
                  <span>请咨询开户行客服</span>
                </div>
              </div>
              <div>
                <el-form-item label="开户银行支行所在地" label-width="185px" class="formaddress small" prop="bankProvince">
                  <el-cascader ref="addressCascader" style="width:400px" :options="city" v-model="form.bankProvince"
                    @change="oncitycascader" clearable></el-cascader>
                </el-form-item>
              </div>
              <div class="btn-group t-r">
                <el-form-item>
                  <el-button @click="onPrev()">上一步</el-button>
                  <el-button class="btn" @click="onsubmitForm('elForm')" type="primary">下一步，完善店铺信息</el-button>
                </el-form-item>
              </div>
            </div>

          </el-form>
        </div>
      </div>
    </ApplyMain>
  </div>
</template>
<script>
import {
  getcompanyintotaxreg,
  getcompanyintobankinfo,
  getcompanyintobusinesslicense,
  companyintotaxreg,
  companyintobankinfo,
} from '@/api/user';
import ApplyMain from '@/components/applymain.vue';
import Cookies from 'js-cookie';
import { cityjson } from '@/utils/cityjson.js';
import {getCookieByName} from '@/utils/helper';
var token =getCookieByName('access_token');
export default {
  name: 'contact',
  components: {
    ApplyMain,
  },
  data() {
    return {
      city: cityjson,
      myHeaders: { Authorization: 'Bearer ' + token },
      uploadUrl: this.$uploadUrlN + '/utils/uploadfile',
      taxRegCertImgfileList: [],
      taxpayerCertTypedata: [
        { id: 1, data_title: '一般纳税人' },
        { id: 2, data_title: '小规模纳税人' },
        { id: 3, data_title: '非增值税纳税人' },
      ],
      taxTypeCodedata: [
        { id: 1, data_title: '0%' },
        { id: 2, data_title: '1%' },
        { id: 3, data_title: '3%' },
        { id: 4, data_title: '6%' },
        { id: 5, data_title: '7%' },
        { id: 6, data_title: '9%' },
        { id: 7, data_title: '10%' },
        { id: 8, data_title: '13%' },
      ],
      form: {
        taxpayerCertType: '一般纳税人',
        taxpayerCertImg: '',
        taxpayerCertImgUrl: '',
        taxpayerCertCode: '',
        taxTypeCode: '6%',
        taxRegCertImg: '',
        taxRegCertImgUrl: '',
        bankAccountName: '',
        bankAccount: '',
        bankName: '',
        bankProvince: '',
        bankProvinceId: 0,
        bankProvinceName: '',
        bankCityId: 0,
        bankCityName: '',
        bankCountyId: 0,
        bankCountyName: '',
        id: 0,
      },
      rules: {
        taxpayerCertCode: [
          { required: true, message: '请输入纳税人识别号', trigger: 'blur' },
        ],
        bankAccountName: [
          { required: false, message: '请输入银行开户名', trigger: 'blur' },
        ],
        bankAccount: [
          { required: true, message: '请输入银行开户账号', trigger: 'blur' },
        ],
        bankName: [
          { required: true, message: '请选择开户行', trigger: 'blur' },
        ],
        bankProvince: [{ required: true, message: '请选择开户银行支行所在地' }],
        taxTypeCode: [
          { required: true, message: '请选择纳税人税码', trigger: 'blur' },
        ],
        taxpayerCertType: [
          { required: true, message: '请选择纳税人类型', trigger: 'blur' },
        ],
        taxpayerCertImgUrl: [
          {
            required: true,
            message: '请上传税务登记证电子版',
            trigger: 'blur',
          },
        ],
        taxRegCertImgUrl: [
          {
            required: true,
            message: '请上传一般纳税人资格证电子版',
            trigger: 'blur',
          },
        ],
      },
    };
  },
  methods: {
    ongetcompanyintobusinesslicense() {
      let params = {};
      getcompanyintobusinesslicense(params).then((res) => {
        if (res.data.code === 1) {
          this.form.bankAccountName = res.data.result.companyName;
        }
      });
    },
    oncitycascader(item) {
      var labels = this.$refs.addressCascader.getCheckedNodes()[0].pathLabels;
      this.form.bankProvinceId = item[0];
      this.form.bankCityId = item[1];
      this.form.bankCountyId = item[2];
      this.form.bankProvince = item;
      this.form.bankProvinceName = labels[0];
      this.form.bankCityName = labels[1];
      this.form.bankCountyName = labels[2];
    },
    onPrev() {
      this.$router.push('/user/apply/information');
    },
    ongetcompanyintotaxreg() {
      let params = {};
      getcompanyintotaxreg(params).then((res) => {
        if (res.data.code === 1) {
          this.form.taxpayerCertType = res.data.result.taxpayerCertType;
          this.form.taxpayerCertImg = res.data.result.taxpayerCertImg;
          this.form.taxpayerCertImgUrl = res.data.result.taxpayerCertImgUrl;
          this.form.taxpayerCertCode = res.data.result.taxpayerCertCode;
          this.form.taxTypeCode = res.data.result.taxTypeCode;
          this.form.taxRegCertImg = res.data.result.taxRegCertImg;
          this.form.taxRegCertImgUrl = res.data.result.taxRegCertImgUrl;
          this.form.id = res.data.result.id;
          this.ongetcompanyintobankinfo();
        }
      });
    },
    ongetcompanyintobankinfo() {
      let params = {};
      getcompanyintobankinfo(params).then((res) => {
        if (res.data.code === 1) {
          this.form.bankAccount = res.data.result.bankAccount;
          this.form.bankName = res.data.result.bankName;
          if (res.data.result.bankProvinceId != 0) {
            this.form.bankProvince = [
              '' + res.data.result.bankProvinceId,
              '' + res.data.result.bankCityId,
              '' + res.data.result.bankCountyId,
            ];
          }
          this.form.bankProvinceId = res.data.result.bankProvinceId;
          this.form.bankProvinceName = res.data.result.bankProvinceName;
          this.form.bankCityId = res.data.result.bankCityId;
          this.form.bankCityName = res.data.result.bankCityName;
          this.form.bankCountyId = res.data.result.bankCountyId;
          this.form.bankCountyName = res.data.result.bankCountyName;
        }
      });
    },
    onsubmitForm() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          let params = this.form;
          companyintotaxreg(params).then((res) => {
            if (res.data.code === 1) {
              this.onsavecompanyintobankinfo();
            } else {
              this.$message.error(res.data.message);
            }
          });
        }
      });
    },
    onsavecompanyintobankinfo() {
      let params = this.form;
      companyintobankinfo(params).then((res) => {
        if (res.data.code === 1) {
          this.$router.push('/user/apply/business');
        } else {
          this.$message.error(res.data.message);
        }
      });
    },
    busSuccess(response, file, fileList) {
      var that = this;
      this.taxpayerCertImgfileList = fileList;
      if (response.code == 1) {
        this.form.taxpayerCertImg = response.result.fileToken;
        this.form.taxpayerCertImgUrl = response.result.url + '/image/256x';
      }
    },
    busError(err, file, fileList) {
      fileList = [];
      return this.$message.error('上传失败');
    },
    busChange(file, fileList) {
      if (fileList.length > 0) {
        this.taxpayerCertImgfileList = [fileList[fileList.length - 1]];
      }
    },
    busSuccesstaxRegCertImg(response, file, fileList) {
      var that = this;
      this.taxRegCertImgfileList = fileList;
      if (response.code == 1) {
        this.form.taxRegCertImg = response.result.fileToken;
        this.form.taxRegCertImgUrl = response.result.url + '/image/256x';
      }
    },
    busRemovetaxRegCertImg() {},
    busErrortaxRegCertImg(err, file, fileList) {
      fileList = [];
      return this.$message.error('上传失败');
    },
    busChangetaxRegCertImg(file, fileList) {
      if (fileList.length > 0) {
        this.taxRegCertImgfileList = [fileList[fileList.length - 1]];
      }
    },
  },
  created() {
    this.ongetcompanyintotaxreg();
    this.ongetcompanyintobusinesslicense();
  },
};
</script>
<style  scoped>
[class*=' el-icon-'],
[class^='el-icon-'] {
  line-height: 20px;
}
.container >>> .el-form-item__content {
  font-size: 12px;
  line-height: 30px;
  color: #666;
}
h4.title {
  font-size: 16px;
  font-family: 'Microsoft Yahei';
  line-height: 180%;
  color: #333333;
  margin-bottom: 5px;
  float: left;
  margin-right: 10px;
}
.container >>> .el-form-item {
  margin-bottom: 8px;
}
.container >>> .el-form-item__label {
  font-size: 12px;
}
.container >>> .el-input__inner {
  height: 30px;
  font-size: 12px;
  padding-left: 10px;
  line-height: 32px;
}
.container >>> .el-input--prefix .el-input__inner {
  padding-left: 30px;
}
.container .form-describe-text {
  margin-left: 185px;
  color: #999;
  top: -9px;
  line-height: 20px;
  margin-top: 9px;
  position: relative;
  font-size: 12px;
}
.container >>> .el-button {
  padding: 8px 20px;
}
.container >>> .btn {
  border: 1px solid #c6e2ff;
  background-color: #31b7f4;
}
.btn:hover,
.btn-normal:hover {
  border: 1px solid;
  color: #409eff;
  border-color: #c6e2ff;
  background-color: #ecf5ff;
}
.form-error-text {
  margin-left: 185px;
  color: #ff3333;
  /* top: 0px; */
  line-height: 20px;
  margin-top: 5px;
  position: relative;
  font-size: 12px;
}
.container .upload-pop-desc {
  margin-left: 0;
  top: -5px;
}
.container >>> .el-upload--picture-card {
  width: 78px;
  height: 78px;
  line-height: 78px;
}
.container >>> .el-upload--picture-card img {
  width: 100%;
  height: 100%;
}
.container .el-upload__tip {
  margin-top: 0;
  line-height: 20px;
  color: #999;
}
.container >>> .el-form-item__error--inline {
  margin-left: 0;
}
</style>